import React from "react";
import pp from "../assets/pp.jpeg";
import TypeWriter from "../utils/Typewriter";

const About = () => {
	return (
		<div className="w-11/12 py-10 md:w-3/5 md:my-auto md:py-20 mx-auto text-white">
			<div className="flex w-full">
				<div className="lg:w-8/12 flex flex-col">
					<p className="text-5xl md:text-8xl drop-in">Hello, 👋</p>
					<div className="flex flex-col justify-start">
						<p className="text-2xl md:text-6xl mt-5 drop-left">
							I'm{" "}
							<span className="text-bold text-black font-semibold">
								{" "}
								Avikal Darolia,
							</span>
							{"   "}
						</p>
						<p className="drop-bottom md:text-3xl mt-5 text-black">
							<span className="text-black">{" > "}</span>
							<TypeWriter text={"Full-Stack Software Developer."} />
						</p>
					</div>
				</div>
				<img
					src={pp}
					alt="Avikal Darolia"
					// className="profile-image w-32 h-32 md:w-64 md:h-64 ml-8 md:ml-0 flex md:mr-auto rounded-full border-white border-4"
					className="profile-image absolute lg:relative right-8 w-32 h-32 md:w-64 md:h-64 ml-8 md:ml-0 flex md:mr-auto rounded-full border-white border-4 profile-image-border"
				/>
			</div>
			{/* <p className="mt-4 md:mt-10 md:text-2xl">
				In addition to being passionate about programming, I am eager to
				challenge myself to do things I've never accomplished before and I
				strive to learn and improve on my skills every day.
			</p> */}
			<p className="mt-4 md:mt-10 md:text-2xl">
				Results-oriented Full Stack Web Developer with experience in building
				and maintaining web applications. Proficient in front-end and back-end
				technologies, delivering highquality, scalable solutions. Seeking to
				contribute and enhance skills in a dynamic development team.
			</p>
		</div>
	);
};

export default About;
